<!-- 满意度统计 -->
<template>
  <div class="_leaveword">
    <div class="tips flex align-items pf">
      <div class="icon">
        <img src="../../assets/img/xinban/zhuzhuang.png" class="w-full h-full" alt="" />
      </div>
      <div class="fontone">{{ "回复满意度统计" }}</div>
      <!-- <div class="fonttwo">{{ '(提示:单击表头单元格进行排序,单击单位名称连接到单位留言列表。)' }}</div> -->
    </div>
    <!-- 表格 -->
    <div class="tab pf1">
      <!-- 表头 -->
      <div class="tab_head">
        <div class="tab_one fontThree">{{ "单位名称" }}</div>
        <div class="tab_two cursor_pointer flex align-items justify-content" @click="fenpaxu(0)">
          <div>{{ "非常满意(%)" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img v-if="feinum == 1" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else-if="feinum == 0" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img v-if="feinum == 1" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else-if="feinum == 0" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
        <div class="tab_three cursor_pointer flex align-items justify-content" @click="manpaxu(1)">
          <div>{{ "满意(%)" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img v-if="mannum == 1" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else-if="mannum == 0" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img v-if="mannum == 1" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else-if="mannum == 0" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
        <div class="tab_five cursor_pointer flex align-items justify-content" @click="bipaxu(2)">
          <div>{{ "较满意(%)" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img v-if="binum == 1" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else-if="binum == 0" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img v-if="binum == 1" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else-if="binum == 0" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
        <div class="tab_six cursor_pointer flex align-items justify-content" @click="banpaxu(3)">
          <div>{{ "一般(%)" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img v-if="bannum == 1" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else-if="bannum == 0" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img v-if="bannum == 1" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else-if="bannum == 0" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
        <div class="tab_eight cursor_pointer flex align-items justify-content" @click="chapaxu(4)">
          <div>{{ "差(%)" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img v-if="chanum == 1" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else-if="chanum == 0" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img v-if="chanum == 1" src="../../assets/img/xinban/shanghui.png" alt="" />
              <img v-else-if="chanum == 0" src="../../assets/img/xinban/shanglv.png" alt="" />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 102px; width: 1200px"></div>
    <div class="mmt">
      <div class="tab_shen">
        <div class="hang" v-for="(item, index) in list" :key="index">
          <div class="tab_one">{{ item.name }}</div>
          <div class="tab_two">{{ item.five }}</div>
          <div class="tab_three">{{ item.four }}</div>
          <div class="tab_five">{{ item.three }}</div>
          <div class="tab_six">{{ item.two }}</div>
          <div class="tab_eight">{{ item.one }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/pj.js";
export default {
  data() {
    return {
      list: [],
      feinum: 0,
      mannum: 2,
      binum: 2,
      bannum: 2,
      chanum: 2,
    };
  },
  mounted() {
    this.liebiao();
  },
  methods: {
    // 差
    chapaxu(type) {
      if (this.chanum == 1) {
        this.chanum = 0;
      } else {
        this.chanum = 1;
      }
      this.feinum= 2,
      this.mannum= 2,
      this.binum= 2,
      this.bannum= 2,
      // this.chanum= 2,
      this.paxu(type, this.chanum);
    },
    //  一般
    banpaxu(type) {
      if (this.bannum == 1) {
        this.bannum = 0;
      } else {
        this.bannum = 1;
      }
      this.feinum= 2,
      this.mannum= 2,
      this.binum= 2,
      // this.bannum= 2,
      this.chanum= 2,
      this.paxu(type, this.bannum);
    },
    // 比较满意
    bipaxu(type) {
      if (this.binum == 1) {
        this.binum = 0;
      } else {
        this.binum = 1;
      }
      this.feinum= 2,
      this.mannum= 2,
      // this.binum= 2,
      this.bannum= 2,
      this.chanum= 2,
      this.paxu(type, this.binum);
    },
    // 满意
    manpaxu(type) {
      if (this.mannum == 1) {
        this.mannum = 0;
      } else {
        this.mannum = 1;
      }
      this.feinum= 2,
      // this.mannum= 2,
      this.binum= 2,
      this.bannum= 2,
      this.chanum= 2,
      this.paxu(type, this.mannum);
    },
    // 非常满意
    fenpaxu(type) {
      if (this.feinum == 1) {
        this.feinum = 0;
      } else {
        this.feinum = 1;
      }
      // this.feinum= 2,
      this.mannum= 2,
      this.binum= 2,
      this.bannum= 2,
      this.chanum= 2,
      this.paxu(type, this.feinum);
    },
    async paxu(type, num) {
      let res = await API.satisfiedList({
        type: type,
        sort: num,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data;
        }
      });
    },
    // type	满意度:0=非常满意,1=满意,2=较满意,3=一般,4=差
    // sort	0 = 倒序(从大到小), 1= 正序(从小到大)
    async liebiao() {
      let res = await API.satisfiedList({
        type: 0,
        sort: 0,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
._leaveword {
  width: 1200px;
}

.pf {
  position: fixed;
  top: 341px;
  width: 1200px;
  z-index: 11;
}

.pf1 {
  position: fixed;
  top: 421px;
  width: 1200px;
  z-index: 12;
  border-bottom: none !important;
}

.tips {
  margin: 0px 0 0 0;
  color: #546569 !important;
  width: 1200px;
  height: 80px;
  background: -webkit-linear-gradient(top, #c9e9fe, #dbf0fe);

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .fontone {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #18191a !important;
    letter-spacing: 0;
    font-weight: 700;
  }

  .fonttwo {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #c90e0e;
    letter-spacing: 0;
    font-weight: 400;
  }
}

// 表头
.tab_head {
  width: 100%;
  height: 52px;
  background: #e8effa;
  display: flex;
  align-items: center;
  padding: 0 27px 0 28px;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #0653ac;
  letter-spacing: 0;
  font-weight: 400;

  .tab_one {
    flex: 1;
    text-align: center;
  }

  .tab_two {
    flex: 1;
    text-align: center;
  }

  .tab_three {
    flex: 1;
    text-align: center;
  }

  .tab_five {
    flex: 1;
    text-align: center;
  }

  .tab_six {
    flex: 1;
    text-align: center;
  }

  .tab_eight {
    flex: 1;
    text-align: center;
  }
}

.tab {
  border: 1px solid #78a6dc;
}

.mmt {
  border: 1px solid #78a6dc;
  border-top: none !important;
}

.tab_shen {
  .hang {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    letter-spacing: 0;
    font-weight: 400;
    padding: 0 27px 0 28px;
    height: 52px;
    color: #191a1c;

    &:hover {
      background: #2670c5 !important;
      font-family: PingFangSC-Medium;
      font-size: 15px !important;
      color: #ffffff !important;
      letter-spacing: 0;
      font-weight: 600;
    }
  }

  .hang:nth-child(even) {
    background-color: #f1f1f1;
  }

  .tab_one {
    flex: 1;
    text-align: center;
  }

  .tab_two {
    flex: 1;
    text-align: center;
  }

  .tab_three {
    flex: 1;
    text-align: center;
  }

  .tab_five {
    flex: 1;
    text-align: center;
  }

  .tab_six {
    flex: 1;
    text-align: center;
  }

  .tab_eight {
    flex: 1;
    text-align: center;
  }
}

.fontThree {
  font-family: PingFangSC-Medium;
  font-size: 15px;
  color: #36383a;
  letter-spacing: 0;
  font-weight: 500;
}

.qiehua {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  position: relative;

  .icon {
    width: 14px;
    height: 9px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .zhuan {
    transform: rotate(180deg);
  }
}

.shang {
  display: flex;
  align-items: flex-end;
  margin: 0 0 1px 0;
}

.xia {
  width: 14.4px;
  margin: 1px 0 0 0;
  display: flex;
  align-items: flex-start;
}
</style>